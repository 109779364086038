import React from 'react';
import * as Icon from 'react-feather';

import Icon4 from "../../assets/images/icon4.png";
import Icon5 from "../../assets/images/icon5.png";
import Icon6 from "../../assets/images/icon6.png";
import Icon7 from "../../assets/images/icon7.png";

// Shape Images
import Shape1 from "../../assets/images/shape1.png";
import Shape2 from "../../assets/images/shape2.svg";
import Shape3 from "../../assets/images/shape3.svg";
import Shape4 from "../../assets/images/shape4.svg";

const RPABenefits = () => {
    return (
        <div className="solutions-area ptb-80">
                        
                <div className="blog1-benefits-grid1 justify-content-center">
                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-solutions-box blog1-benefits">
                            <div className="icon">
                                <img src={Icon4} alt="image" />
                            </div>
                            <h3>
                               Increased Efficiency:
                            </h3>
                            <p>RPA can automate repetitive and time-consuming tasks, freeing up employees to focus on more important tasks that require human decision-making and creativity. This leads to increased efficiency and productivity.</p>
                            
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-solutions-box blog1-benefits">
                            <div className="icon">
                                <img src={Icon5} alt="image" />
                            </div>
                            <h3>
                               
                                Cost Savings
                            </h3>
                            <p>RPA can help businesses save money by reducing the need for human labor an minimizing errors. By automating tasks, businesses can optimize their resources and get more done with fewer resources.</p> 
                            
                        </div>
                    </div>

                     </div>
                     <div className="blog1-center col-lg-3 col-sm-6 col-md-6">
                        <div className="single-solutions-box blog1-benefits">
                            <div className="icon">
                                <img src={Icon6} alt="image" />
                            </div>
                            <h3>
                                    ROI
                            </h3>
                            <p>RPA can deliver a significant return on investment (ROI) by reducing operational costs and increasing productivity. According to a study by Deloitte, companies that implement RPA can achieve ROI in as little as six to nine months.</p>

                            
                        </div>
                    </div>
                   
                <div className="blog1-benefits-grid1 justify-content-center">
                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-solutions-box blog1-benefits">
                            <div className="icon">
                                <img src={Icon7} alt="image" />
                            </div>
                            <h3>
                                Improved Accuracy
                            </h3>
                            <p>RPA is highly accurate and can reduce the risk of errors that are typically associated with manual data entry and processing. This can lead to improved data quality and more accurate business decisions.</p>

                            
                        </div>
                    </div>
                    
                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-solutions-box blog1-benefits" >
                            <div className="icon">
                                <img src={Icon6} alt="image" />
                            </div>
                            <h3>
                                Scalability
                            </h3>
                            <p>RPA can be easily scaled up or down to meet changing business needs. This makes it an ideal solution for businesses that need to quickly adapt to changing market conditions or business demands</p>
                            
                        </div>
                    </div>
                </div>
                
           
        </div>
    );
}

export default RPABenefits;